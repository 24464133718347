import React from "react"
import { Box, Text, Flex } from "@chakra-ui/core"

import Layout from "containers/layout"
import SEO from "components/seo"
import TitleText from "components/textAndTitle"
import { useTranslation } from "gatsby-plugin-react-i18next"

export const DatenschutzTempl = ({ location, data, en }) => {
  const { t } = useTranslation()

  return (
    <Layout path={location.pathname}>
      <SEO
        title={data.strapi?.datenschutz?.meta?.title ?? "Datenschutz"}
        description={data.strapi?.datenschutz?.meta?.description}
      />
      <Box
        pt={["16em", "15.5em", "13em"]}
        pr={["4em", "10em", "5em"]}
        minHeight={["100vh", "100vh", "calc(100vh - 5.6em)"]}
      >
        <Text
          color="red"
          fontSize={["5xl", "5xl", "4xl"]}
          pb={["1vw", "4vw", "1vw"]}
        >
          {t("Datenschutz")}
        </Text>
        <Flex direction={["column", "column", "row"]}>
          {data.strapi?.datenschutz &&
            data.strapi.datenschutz[en ? "inhalt_en" : "inhalt"] &&
            data.strapi.datenschutz[
              en ? "inhalt_en" : "inhalt"
            ].map((item, index) => (
              <TitleText key={index} text={item.text} title={item.title} />
            ))}
        </Flex>
      </Box>
    </Layout>
  )
}

export default DatenschutzTempl
