import React from "react"
import { graphql } from "gatsby"
import DatenschutzTempl from "../../pageTemplates/datenshutz"

const Datenschutz = ({ location, data }) => (
  <DatenschutzTempl data={data} location={location} en />
)

export const query = graphql`
  query {
    strapi {
      datenschutz {
        meta {
          title
          description
        }
        inhalt_en {
          ... on STRAPI_ComponentPageText {
            id
            text
          }
          ... on STRAPI_ComponentPageTitleText {
            id
            title
            text
          }
        }
      }
    }
  }
`

export default Datenschutz
