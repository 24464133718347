import React from "react"
import { Box } from "@chakra-ui/core"

import ReactMarkdown from "react-markdown"
import RemarkBreaks from "remark-breaks"

import styles from "./markdown.module.css"

const Text = ({ title, text }) => {
  return (
    <Box pr={["3vw", "3vw", "5vw"]}>
      {title && (
        <Box color="red" fontSize={["3xl", "xl", "xl"]}>
          {title}
        </Box>
      )}
      {text && (
        <Box color="green" fontSize={["3xl", "xl", "md"]}>
          <ReactMarkdown
            source={text}
            className={styles.md}
            plugins={[RemarkBreaks]}
          />
        </Box>
      )}
    </Box>
  )
}

export default Text
